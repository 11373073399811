import { useState, useCallback } from 'react';

function useResetableState(initialCount) {
  const [count, setCount] = useState(initialCount);

  const reset = useCallback(() => {
    setCount(initialCount);
  }, [initialCount]);

  return (
  [  count,
    setCount,
    reset,
  ])
}

export default useResetableState