import Block from './assets/Block';

function board({matrix, movingBlocks, ghostBlocks}) {

    const _createBlock = (piece, pos, id, ghost) => {
      return <Block piece={piece} pos={pos} ghost={ghost} key={id}></Block>
    }

    matrix = Object.entries(matrix).map((block, _) => {
      block = block[1]
      return _createBlock(block.piece, block.pos, block.id, false)
    });
    movingBlocks = movingBlocks?.map((block, i) => {
      if (block.pos.y <= 20){
        return _createBlock(block.piece, block.pos, block.id, false)
      }
    })
    ghostBlocks = ghostBlocks?.map((block, i) => {
      if (block.pos.y <= 20){
        return _createBlock(block.piece, block.pos, block.id, true)
      }
    })

    return (
        <div className='board mainBoard'>{matrix}{movingBlocks}{ghostBlocks}</div>
    );
  }
  
  export default board;
  