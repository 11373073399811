import Block from "./Block"

function PreView({blocks}){

    blocks = blocks?.map((block, i) => {
        const pos = {x: block.pos.x - 3, y: block.pos.y + 3}
        return <Block piece={block.piece} pos={pos} ghost={false} key={block.id}></Block>
    })
    return (
        <div className="board preViewBoard">
            {blocks}
        </div>
    )
}

export default PreView
