import arrow from './css/svg/arrow.svg'
import Key from './PopUpKey'

function PopUpStart ({handleClick}){
    return (
        <div className={'popUp start'}>
            <div>Welcome!</div>
            <img src={arrow} style={{width: "20px", transform: "rotate(-90deg)"}} alt=''/>
            <div className='content'>
                Press 
                <Key onClick={handleClick}>Enter</Key> 
                to play
            </div>
        </div>
    )
}

export default PopUpStart