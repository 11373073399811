import { useState } from 'react';
import './css/popup.css'
import useKeypress from 'react-use-keypress';

function PlayerRegistration({setRegistration}){
    const [ name, setName ] = useState("")

    function handleSubmit(e) {
        e.preventDefault();
        console.log(name);
        setRegistration(name)
    }

    useKeypress('Enter', handleSubmit)
  
    return(
        <div className="popUp">
            <form onSubmit={handleSubmit}>
                <label>
                    Enter your name: <br/>
                    <input
                        type="text" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <button className='button' type='submit'>Submit</button>
            </form>
        </div>  
    )
}

export default PlayerRegistration