import loos from './css/svg/loos.svg'
import Key from './PopUpKey'

function PopUpLoos ({handleClick}){
    return (
        <div className={'popUp loos'}>
            <div>Looser</div>
            <img src={loos} style={{width: "20px"}} alt=''/>
            <div className='content'>
                Press 
                <Key onClick={handleClick}>Enter</Key> 
                to play again
            </div>
        </div>
    )
}

export default PopUpLoos