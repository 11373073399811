import pause from './css/svg/pause.svg'
import Key from './PopUpKey'

function PopUpPause ({handlePause, handleRestart}){
    return (
        <div className={'popUp pause'}>
            <div>Take a breath</div>
            <img src={pause} style={{width: "20px"}} alt=''/>
            <div className='content'>
                Press 
                <Key onClick={handlePause}>ESC</Key> 
                to continue or 
                <Key onClick={handleRestart}>Enter</Key> 
                to restart</div>
        </div>
    )
}

export default PopUpPause