import { useCookies } from 'react-cookie';
import { useState, useRef } from "react";
import useKeypress from 'react-use-keypress';
import useResetableState from './components/assets/hooks/ResetableState';

import './App.css'
import './components/Game.css'
import './components/Stats.css'
import './components/Controls.css'
import './components/assets/css/Board.css'
import './components/assets/css/assets.css'
import './components/assets/css/table.css'
import './components/assets/css/popup.css'

import Scoreboard from "./components/Scoreboard";
import Game from "./components/Game"; 
import Stats from "./components/Stats";
import PlayerRegistration from "./components/assets/PlayerRegistration";
import PopUpStart from './components/assets/PopUpStart';

const pointsPerClearRows = {
  0: 0,
  1: 1, 	
  2: 3, 	
  3: 6, 	
  4: 10 
}

function App() {
  const [points, setPoints, resetPoints] = useResetableState(0)
  const [rowsCleared, setRowsCleared, resetRowsCleared] = useResetableState(0) 
  const levelCalc = 0.07*points**0.9
  const level = Math.ceil(levelCalc) >= 1 ? Math.ceil(levelCalc): 1
  const [numGames, setNumGames] = useState(0)

  const [isSessionStarted, setIsSessionStarted] = useState(false)

  const [isRegistrationComplete, setRegistrationComplete] = useState(false)
  const isScoreTrackingActive = useRef(false)
  const [name, setName] = useState("")
  const [personalHighscore, setPersonalHighscore] = useState(0)
  const [cookies, setCookie, removeCookie] = useCookies(['registration']);

  const handleNewRegistration = (tempName) => { //Users enters name
    if (tempName){
      saveRegistraton(tempName)
      retrieveRecord(tempName)
      setName(tempName)
      isScoreTrackingActive.current = true
      setRegistrationComplete(true)
      handleStart()
    }
  }

  const getRegistrationFromCookie = () => { //User already regsistered, get cookie
    const tempName = cookies["registration"]
    if (tempName){
      isScoreTrackingActive.current = true
      setRegistrationComplete(true)
      retrieveRecord(tempName)
      setName(tempName)
      return true
    }

    else{
      return false
    }
  }

  const saveRegistraton = (tempName) => {
    setCookie('registration', tempName, {sameSite : "strict", maxAge: 604800})
  }
  
  const retrieveRecord = (tempName)=> { //get PersonalHighscore, if user played before
    fetch("https://tetris-backend.onrender.com/api/retrieveRecord", {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ "name": tempName})
    }).then((res) => {
      if (res.ok){
        res.text().then(res => {
            res = JSON.parse(res)
            setPersonalHighscore(res["POINTS"]);
        })
      }
      else{
        setPersonalHighscore(0)
      }
    })
  }

  const handleStart = (e) => { //called by Game and by App
    e && e.preventDefault();
    if (isRegistrationComplete){
      resetRowsCleared()
      resetPoints()
      setIsSessionStarted(true)
      setNumGames(() => numGames+1) //by replacing the key, the orignal state of Game will be discarded
    }
  }
  
  const handleMoreRowsCleared = (numRows) => { //called by Game
    const tempPoints = points+pointsPerClearRows[Math.abs(numRows)]

    if (isScoreTrackingActive.current && tempPoints > personalHighscore){
      setPersonalHighscore(tempPoints)
      fetch("https://tetris-backend.onrender.com/api/addRecord", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ "name": name, "points": tempPoints })
      })
    } 

    setRowsCleared((rc) => rc+numRows)
    setPoints(tempPoints)
  }

  const getSessionStartPopup = () => {
    if(!name && !getRegistrationFromCookie()){ //User is not Registerd and there is no Registration cookie that could be used for a Registration
      return (
        <PlayerRegistration
          setRegistration={handleNewRegistration}
        />
      )
    }
    else { //User is Registered 
      return(
        <PopUpStart handleClick={handleStart}/>
      )
    }
  }
  
  useKeypress('Enter', handleStart)

  return (
    <div className="container">
      {!isSessionStarted && getSessionStartPopup()}
      <Stats 
        rowsCleared={rowsCleared}
        points={points}
        level={level}
      />
      <Game
        key={numGames}
        moreRowsCleared={handleMoreRowsCleared}
        rowsCleared={rowsCleared}
        level={level}
        isSessionStarted={isSessionStarted}
        handleStart={handleStart}
        preRenderedScoreboard={<Scoreboard key={personalHighscore}/>} //If rendered in Game, it would rerender at each row drop. This way, the scoreboard will be refetched only when the highscore is broken.
      />
    </div>
  );
}

export default App;
