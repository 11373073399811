import './css/assets.css'

function StartButton({onClick, isStart}) {

    return (
      <button
        className={`button ${isStart ? 'start-button': 'stop-button'}`}
        onClick={onClick}
      />
    );
  }
  
  export default StartButton;
  