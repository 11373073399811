import ControlButton from "./assets/ControlButton"
import RotateButton from "./assets/RotateButton"
import StartButton from "./assets/StartButton"
import RapidDescentButton from "./assets/RapidDescentButton"

function Controls({onLeft, onRight, onDown, onRotate, onStart, onStop, onRapidDescent, isGameActive}){
    return(
        <div className="controls">
            <div className="gamepad">
                <ControlButton 
                    turn={"1"}
                    key={1}
                    onClick={onLeft}
                />
                <ControlButton 
                    turn={"3"}
                    key={2}
                    onClick={onRight}
                />
                <ControlButton 
                    turn={"4"}
                    key={3}
                    onClick={onDown}
                />
            </div>
            <RapidDescentButton 
                onClick={onRapidDescent}
            />
            <RotateButton 
                onClick={onRotate}
            />
            <StartButton 
                onClick={!isGameActive ? onStart: onStop}
                isStart={!isGameActive}
            />
        </div>
    )
}

export default Controls