import './css/assets.css'

function RapidDescentButton({onClick}) {

    return (
      <button
        className={`button rapid-descent-button`}
        onClick={onClick}
      />
    );
  }
  
  export default RapidDescentButton;
  