function Stats({rowsCleared, level, points}){
    return (
        <div className="stats">
            <div className='stat'>
                <p className='name'>Rows cleared</p>
                <p className='value'>{rowsCleared}</p>
            </div>
            <div className='stat'>
                <p className='name'>Level</p>
                <p className='value'>{level}</p>
            </div>
            <div className='stat'>
                <p className='name'>Points</p>
                <p className='value'>{points}</p>
            </div>
        </div>
    )
}

export default Stats;