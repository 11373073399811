import './css/assets.css'

const pos = {
  1: "a",
  3: "b",
  4: "c"
}

function ControlButton({turn, onClick}) {
    return (
      <button 
        className='control-button button'
        style={{transform: `rotate(${turn/4}turn)`, gridArea: pos[turn]}} 
        onClick={onClick}  
      />
    );
  }
  
  export default ControlButton;
  