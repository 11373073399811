import './css/assets.css'

function RotateButton({onClick}) {
    return (
      <button
        className={'rotate-button button'}
        onClick={onClick}
      />
    );
  }
  
  export default RotateButton;
  