import { useState, useEffect } from 'react';

function Scoreboard() {
    const [ score, setScore ] = useState() 

    useEffect(()=> {
        fetch("https://tetris-backend.onrender.com/api/scoreboard", {
          method: 'get',
        }).then((res) => {
            res.text().then(res => {
                res = JSON.parse(res)
                setScore(res);
            })
        })
    }, [])

    return (
        <>
        { (score) && (
            <table className="scoreboard">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                {score.map((item, index) => (
                    <tr key={index}>
                        <td>{item.NAME}</td>
                        <td>{item.POINTS}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
        }
        </>
    )
}

export default Scoreboard